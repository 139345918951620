<template>
  <b-card
    no-body
    class="rounded overflow-hidden"
  >
    <b-card-body class="pb-0">
      <div class="d-flex justify-content-between">
        <b-avatar
          class="mb-1"
          :variant="`light-${color}`"
          size="45"
        >
          <feather-icon
            size="21"
            :icon="icon"
          />
        </b-avatar>
        <b-spinner
          v-if="isLoading"
          small
          :variant="color"
        />
      </div>
      <div class="truncate">
        <div class="d-flex flex-grow-1 align-items-center mb-25">
          <h2 class="font-weight-bolder mb-0 mr-50">
            {{ statistic }}
          </h2>
          <b-badge
            v-if="!isLoading"
            :variant="incomeChangeBadgeVariant"
            class="mr-1"
          >
            <feather-icon
              :icon="incomeChangeIconVariant"
              class="mr-25 font-medium-1"
            />
            <span class="font-medium-1">{{ incomeChangePercentage }}%</span>
          </b-badge>
          <feather-icon
            v-if="!isLoading"
            v-b-tooltip.hover.v-dark.top="this.$options.filters.dateFromNow(lastUpdate)"
            icon="InfoIcon"
            class="font-medium-5"
            variant="outline-dark"
          />
        </div>
        <span v-if="!isLoading">{{ statisticTitle }}</span>
      </div>
    </b-card-body>

    <vue-apex-charts
      type="area"
      height="100"
      width="100%"
      :options="chartOptionsComputed"
      :series="chartData"
    />

  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BAvatar, BBadge, BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
// eslint-disable-next-line import/named
import { historicalChartOptions } from '@/chart/chartOptions'

export default {
  components: {
    BSpinner,
    BBadge,
    VueApexCharts,
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
    dates: {
      type: Array,
      default: () => [],
    },
    incomeToday: {
      type: Number,
      default: 0,
    },
    incomeYesterday: {
      type: Number,
      default: 0,
    },
    lastUpdate: {
      type: Date,
      default: new Date(),
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    formatType: {
      type: String,
      default: '',
    },
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(historicalChartOptions))
        options.theme.monochrome.color = $themeColors[this.color]
        options.xaxis.categories = this.dates
        if (this.formatType === 'Idr') {
          options.yaxis = {
            labels: {
              formatter(value) {
                if (value >= 1e9) {
                  return `${new Intl.NumberFormat('id-ID', {
                    style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0,
                  }).format(value / 1e9).replace(',00', '')}B`
                } if (value >= 1e6) {
                  return `${new Intl.NumberFormat('id-ID', {
                    style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0,
                  }).format(value / 1e6).replace(',00', '')}M`
                } if (value >= 1e3) {
                  return `${new Intl.NumberFormat('id-ID', {
                    style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0,
                  }).format(value / 1e3).replace(',00', '')}K`
                }
                return new Intl.NumberFormat('id-ID', {
                  style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0,
                }).format(value).replace(',00', '')
              },
            },
          }
        } else {
          options.yaxis = {
            labels: {
              formatter: value => `${value} ${this.formatType}`,
            },
          }
        }

        return options
      }
      return this.chartOptions
    },
    incomeChangePercentage() {
      if (this.incomeToday === this.incomeYesterday) {
        return 0
      }
      const change = this.incomeToday - this.incomeYesterday
      let percentageChange = (change / this.incomeYesterday) * 100
      percentageChange = Math.round(percentageChange) // round to nearest integer
      return Math.abs(percentageChange > 100 ? 100 : percentageChange)
    },
    isIncomeDecreasing() {
      return (this.incomeToday - this.incomeYesterday) < 0
    },
    incomeChangeText() {
      if (this.incomeToday === this.incomeYesterday) {
        return 'Stabil'
      }
      return this.isIncomeDecreasing ? 'Turun' : 'Naik'
    },
    incomeChangeBadgeVariant() {
      if (this.incomeToday === this.incomeYesterday) {
        return 'light-secondary'
      }
      return this.isIncomeDecreasing ? 'light-danger' : 'light-success'
    },
    incomeChangeIconVariant() {
      if (this.incomeToday === this.incomeYesterday) {
        return 'MenuIcon'
      }

      return this.isIncomeDecreasing ? 'ArrowDownIcon' : 'ArrowUpIcon'
    },
  },
}
</script>
