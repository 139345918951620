<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="3"
        sm="6"
      >
        <HistoricalCard
          icon="DollarSignIcon"
          :statistic="historicalPaymentLoadingItems ? 'Loading...' : this.$options.filters.rupiah(historicalPaymentItemData[0].payment_total)"
          statistic-title="Total Pembayaran Hari Ini"
          color="success"
          :chart-data="historicalPaymentLoadingItems ? [{
            name: 'Pembayaran',
            data: [0, 0, 0, 0, 0, 0, 0],
          }] : [
            {
              name: 'Pembayaran',
              data: historicalPaymentItemsData.map(item => item.payment_total),
            },
          ]"
          :dates="historicalPaymentLoadingItems ? [] : historicalPaymentItemsData.map(item => this.$options.filters.dateShort(item.date))"
          :income-today="historicalPaymentLoadingItems ? 0 : historicalPaymentItemData[0].payment_total"
          :income-yesterday="historicalPaymentLoadingItems ? 0 : historicalPaymentItemsData[6].payment_total"
          :last-update="historicalPaymentLoadingItems ? new Date() : historicalPaymentLastUpdate"
          :is-loading="historicalPaymentLoadingItems"
          format-type="Idr"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <HistoricalCard
          icon="UserIcon"
          :statistic="historicalRegistrationLoadingItems ? 'Loading...' : `${historicalRegistrationItemData[0].user_total} Pengguna`"
          statistic-title="Total Pendaftaran Hari Ini"
          color="primary"
          :chart-data="historicalRegistrationLoadingItems ? [{
            name: 'Pendaftaran',
            data: [0, 0, 0, 0, 0, 0, 0],
          }] : [
            {
              name: 'Pendaftaran',
              data: historicalRegistrationItemsData.map(item => item.user_total),
            },
          ]"
          :dates="historicalRegistrationLoadingItems ? [] : historicalRegistrationItemsData.map(item => this.$options.filters.dateShort(item.date))"
          :income-today="historicalRegistrationLoadingItems ? 0 : historicalRegistrationItemData[0].user_total"
          :income-yesterday="historicalRegistrationLoadingItems ? 0 : historicalRegistrationItemsData[6].user_total"
          :last-update="historicalRegistrationLoadingItems ? new Date() : historicalRegistrationLastUpdate"
          :is-loading="historicalRegistrationLoadingItems"
          format-type="Pengguna"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="topProductItemsData.length"
        xl="4"
      >
        <ListCard
          title="Product Leaderboard"
          :data="topProductItemsData">
          <template #data="{ data }">
            {{ data.total_purchased | number }} qty
          </template>
        </ListCard>
      </b-col>
      <b-col
        v-if="topBrandItemsData.length"
        xl="4"
      >
        <ListCard
          title="Brand Leaderboard"
          :data="topBrandItemsData">
          <template #data="{ data }">
            {{ data.total | number }} qty
          </template>
        </ListCard>
      </b-col>
      <b-col
        v-if="topBuyerItemsData.length"
        xl="4"
      >
        <ListCard
          title="Buyer Leaderboard"
          :data="topBuyerItemsData">
          <template #data="{ data }">
            Rp {{ data.total_expenses | number }}
          </template>
        </ListCard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import moment from 'moment'
import ListCard from '@/layouts/components/Report/ListCard.vue'
import HistoricalCard from '@/layouts/components/Report/HistoricalCard.vue'

export default {
  components: {
    HistoricalCard,
    ListCard,
    BRow,
    BCol,
  },
  data() {
    return {
      abortController: null,

      daysLimitItems: {
        value: 7,
        text: 'Last 7 Days',
      },

      lastDays: [
        {
          value: 7,
          text: 'Last 7 Days',
        },
        {
          value: 14,
          text: 'Last 14 Days',
        },
        {
          value: 30,
          text: 'Last 30 Days',
        },
      ],
    }
  },
  computed: {
    ...mapState('topProductReport', {
      topProductItemsData: 'itemsData',
      topProductLoadingItems: 'loadingItems',
    }),
    ...mapState('topBrandReport', {
      topBrandItemsData: 'itemsData',
      topBrandLoadingItems: 'loadingItems',
    }),
    ...mapState('topBuyerReport', {
      topBuyerItemsData: 'itemsData',
      topBuyerLoadingItems: 'loadingItems',
    }),
    ...mapState('historicalPaymentsReport', {
      historicalPaymentItemsData: 'itemsData',
      historicalPaymentItemData: 'itemData',
      historicalPaymentLastUpdate: 'lastUpdate',
      historicalPaymentLoadingItems: 'loadingItems',
    }),
    ...mapState('historicalRegistrationReport', {
      historicalRegistrationItemsData: 'itemsData',
      historicalRegistrationItemData: 'itemData',
      historicalRegistrationLastUpdate: 'lastUpdate',
      historicalRegistrationLoadingItems: 'loadingItems',
    }),
  },
  beforeRouteLeave(_, __, next) {
    this.abortController.abort()
    next()
  },
  mounted() {
    this.abortController = new AbortController()

    this.loadTopProductItems()
    this.loadTopBrandItems()
    this.loadTopBuyerItems()
    this.loadHistoricalPayments()
    this.loadHistoricalPayment()
    this.loadHistoricalRegistrations()
    this.loadHistoricalRegistration()
  },
  methods: {
    loadTopProductItems() {
      const { signal } = this.abortController
      return this.$store.dispatch('topProductReport/getItems', {
        signal,
        startedAt: moment()
          .subtract(this.daysLimitItems.value - 1, 'days')
          .startOf('day')
          .utc()
          .toISOString()
          .slice(0, 10),
        endedAt: moment().startOf('day').utc()
          .toISOString()
          .slice(0, 10),
      })
    },
    loadTopBrandItems() {
      const { signal } = this.abortController
      return this.$store.dispatch('topBrandReport/getItems', {
        signal,
        startedAt: moment()
          .subtract(this.daysLimitItems.value - 1, 'days')
          .startOf('day')
          .utc()
          .toISOString()
          .slice(0, 10),
        endedAt: moment().startOf('day').utc()
          .toISOString()
          .slice(0, 10),
      })
    },
    loadTopBuyerItems() {
      const { signal } = this.abortController
      return this.$store.dispatch('topBuyerReport/getItems', {
        signal,
        startedAt: moment()
          .subtract(this.daysLimitItems.value - 1, 'days')
          .startOf('day')
          .utc()
          .toISOString()
          .slice(0, 10),
        endedAt: moment().startOf('day').utc()
          .toISOString()
          .slice(0, 10),
      })
    },
    loadHistoricalPayments() {
      const { signal } = this.abortController
      return this.$store.dispatch('historicalPaymentsReport/getItems', {
        signal,
      })
    },
    loadHistoricalPayment() {
      return this.$store.dispatch('historicalPaymentsReport/getItem')
    },
    loadHistoricalRegistrations() {
      const { signal } = this.abortController
      return this.$store.dispatch('historicalRegistrationReport/getItems', {
        signal,
      })
    },
    loadHistoricalRegistration() {
      return this.$store.dispatch('historicalRegistrationReport/getItem')
    },
  },
}
</script>

<style>

</style>
