<template>
  <b-card no-body>
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title class="d-flex align-items-center">
        {{ title }} <b-spinner
          v-if="loading"
          class="ml-50 s-14"
          small
        />
      </b-card-title>
      <b-dropdown
        no-caret
        right
        :text="daysLimit.text"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      >
        <b-dropdown-item
          v-for="(day, index) in lastDays"
          :key="`revenue-services-days-limit-${index}`"
          @click="selectDaysLimit(day)"
        >
          {{ day.text }}
        </b-dropdown-item>
        <b-dropdown-divider v-if="false" />
        <b-dropdown-item v-if="false">
          Custom
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <!--/ title and dropdown -->

    <b-list-group
      class="my-1"
      flush
    >
      <b-list-group-item
        v-for="(item, index) in data"
        :key="`leaderboard-${index}`"
      >
        <b-row align-v="center">
          <b-col
            cols="2"
            class="text-center"
          >
            <div
              v-if="index === 0"
              class="ml-50"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 8.20349C10.9688 8.20349 10.125 7.35974 10.125 6.32849C10.125 5.27399 10.9688 4.45349 12 4.45349C13.0312 4.45349 13.875 5.27399 13.875 6.32849C13.875 7.35974 13.0312 8.20349 12 8.20349ZM4.52325 7.42949C5.34375 7.42949 6 8.10899 6 8.92949C6 9.77324 5.34375 10.4295 4.52325 10.4295C3.6795 10.4295 3 9.77324 3 8.92949C3 8.10899 3.6795 7.42949 4.52325 7.42949ZM19.5233 7.42949C20.3438 7.42949 21 8.10899 21 8.92949C21 9.77324 20.3438 10.4295 19.5233 10.4295C18.6795 10.4295 18 9.77324 18 8.92949C18 8.10899 18.6795 7.42949 19.5233 7.42949ZM6.16425 17.7892L4.92225 10.992C5.29725 10.9215 5.649 10.7107 5.93025 10.476C6.7035 11.1795 7.68825 11.859 8.62575 11.859C9.75075 11.859 10.665 10.1242 11.298 8.67149C11.5088 8.74199 11.7667 8.81249 12.0015 8.81249C12.2362 8.81249 12.4935 8.74199 12.705 8.67149C13.338 10.125 14.2523 11.859 15.3773 11.859C16.3148 11.859 17.2995 11.1795 18.0728 10.476C18.354 10.7107 18.7057 10.9215 19.0807 10.992L17.8387 17.7892H6.1665H6.16425ZM5.90625 18.375H18.0938V19.5232H5.90625V18.375Z"
                  fill="#F4CD00"
                />
              </svg>
            </div>
            <div
              v-else-if="index === 1"
              class="ml-50"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 8.20349C10.9688 8.20349 10.125 7.35974 10.125 6.32849C10.125 5.27399 10.9688 4.45349 12 4.45349C13.0312 4.45349 13.875 5.27399 13.875 6.32849C13.875 7.35974 13.0312 8.20349 12 8.20349ZM4.52325 7.42949C5.34375 7.42949 6 8.10899 6 8.92949C6 9.77324 5.34375 10.4295 4.52325 10.4295C3.6795 10.4295 3 9.77324 3 8.92949C3 8.10899 3.6795 7.42949 4.52325 7.42949ZM19.5233 7.42949C20.3438 7.42949 21 8.10899 21 8.92949C21 9.77324 20.3438 10.4295 19.5233 10.4295C18.6795 10.4295 18 9.77324 18 8.92949C18 8.10899 18.6795 7.42949 19.5233 7.42949ZM6.16425 17.7892L4.92225 10.992C5.29725 10.9215 5.649 10.7107 5.93025 10.476C6.7035 11.1795 7.68825 11.859 8.62575 11.859C9.75075 11.859 10.665 10.1242 11.298 8.67149C11.5088 8.74199 11.7667 8.81249 12.0015 8.81249C12.2362 8.81249 12.4935 8.74199 12.705 8.67149C13.338 10.125 14.2523 11.859 15.3773 11.859C16.3148 11.859 17.2995 11.1795 18.0728 10.476C18.354 10.7107 18.7057 10.9215 19.0807 10.992L17.8387 17.7892H6.1665H6.16425ZM5.90625 18.375H18.0938V19.5232H5.90625V18.375Z"
                  fill="#B8B8B8"
                />
              </svg>
            </div>
            <div
              v-else-if="index === 2"
              class="ml-50"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 8.20349C10.9688 8.20349 10.125 7.35974 10.125 6.32849C10.125 5.27399 10.9688 4.45349 12 4.45349C13.0312 4.45349 13.875 5.27399 13.875 6.32849C13.875 7.35974 13.0312 8.20349 12 8.20349ZM4.52325 7.42949C5.34375 7.42949 6 8.10899 6 8.92949C6 9.77324 5.34375 10.4295 4.52325 10.4295C3.6795 10.4295 3 9.77324 3 8.92949C3 8.10899 3.6795 7.42949 4.52325 7.42949ZM19.5233 7.42949C20.3438 7.42949 21 8.10899 21 8.92949C21 9.77324 20.3438 10.4295 19.5233 10.4295C18.6795 10.4295 18 9.77324 18 8.92949C18 8.10899 18.6795 7.42949 19.5233 7.42949ZM6.16425 17.7892L4.92225 10.992C5.29725 10.9215 5.649 10.7107 5.93025 10.476C6.7035 11.1795 7.68825 11.859 8.62575 11.859C9.75075 11.859 10.665 10.1242 11.298 8.67149C11.5088 8.74199 11.7667 8.81249 12.0015 8.81249C12.2362 8.81249 12.4935 8.74199 12.705 8.67149C13.338 10.125 14.2523 11.859 15.3773 11.859C16.3148 11.859 17.2995 11.1795 18.0728 10.476C18.354 10.7107 18.7057 10.9215 19.0807 10.992L17.8387 17.7892H6.1665H6.16425ZM5.90625 18.375H18.0938V19.5232H5.90625V18.375Z"
                  fill="#C47930"
                />
              </svg>
            </div>
            <span
              v-else
              class="ml-50"
            >{{ index + 1 }}</span>
          </b-col>
          <b-col
            col
            class="text-truncate"
          >
            <TableField
              type="profile"
              :image="item.image_url"
              :value="item.name"
              :url="`/product/${item.id}/edit`"
            />
          </b-col>
          <b-col
            cols="auto"
            class="text-right"
          >
            <div class="pr-75 text-nowrap">
              <slot
                name="data"
                :data="item"
              />
            </div>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownDivider,
  BDropdownItem, BListGroup, BListGroupItem,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import TableField from '@/layouts/components/TableField.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCardHeader,
    BCardTitle,
    BDropdownItem,
    BSpinner,
    BDropdown,
    BCol,
    BRow,
    BDropdownDivider,
    TableField,
    BCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,

      daysLimit: {
        value: 7,
        text: 'Last 7 Days',
      },

      lastDays: [
        {
          value: 7,
          text: 'Last 7 Days',
        },
        {
          value: 14,
          text: 'Last 14 Days',
        },
        {
          value: 30,
          text: 'Last 30 Days',
        },
      ],
    }
  },
  methods: {
    selectDaysLimit(day) {
      this.daysLimit = day
      this.load()
    },
  },
}
</script>

<style scoped>

</style>
