var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"lg":"3","sm":"6"}},[_c('HistoricalCard',{attrs:{"icon":"DollarSignIcon","statistic":_vm.historicalPaymentLoadingItems ? 'Loading...' : this.$options.filters.rupiah(_vm.historicalPaymentItemData[0].payment_total),"statistic-title":"Total Pembayaran Hari Ini","color":"success","chart-data":_vm.historicalPaymentLoadingItems ? [{
          name: 'Pembayaran',
          data: [0, 0, 0, 0, 0, 0, 0],
        }] : [
          {
            name: 'Pembayaran',
            data: _vm.historicalPaymentItemsData.map(function (item) { return item.payment_total; }),
          } ],"dates":_vm.historicalPaymentLoadingItems ? [] : _vm.historicalPaymentItemsData.map(function (item) { return this$1.$options.filters.dateShort(item.date); }),"income-today":_vm.historicalPaymentLoadingItems ? 0 : _vm.historicalPaymentItemData[0].payment_total,"income-yesterday":_vm.historicalPaymentLoadingItems ? 0 : _vm.historicalPaymentItemsData[6].payment_total,"last-update":_vm.historicalPaymentLoadingItems ? new Date() : _vm.historicalPaymentLastUpdate,"is-loading":_vm.historicalPaymentLoadingItems,"format-type":"Idr"}})],1),_c('b-col',{attrs:{"lg":"3","sm":"6"}},[_c('HistoricalCard',{attrs:{"icon":"UserIcon","statistic":_vm.historicalRegistrationLoadingItems ? 'Loading...' : ((_vm.historicalRegistrationItemData[0].user_total) + " Pengguna"),"statistic-title":"Total Pendaftaran Hari Ini","color":"primary","chart-data":_vm.historicalRegistrationLoadingItems ? [{
          name: 'Pendaftaran',
          data: [0, 0, 0, 0, 0, 0, 0],
        }] : [
          {
            name: 'Pendaftaran',
            data: _vm.historicalRegistrationItemsData.map(function (item) { return item.user_total; }),
          } ],"dates":_vm.historicalRegistrationLoadingItems ? [] : _vm.historicalRegistrationItemsData.map(function (item) { return this$1.$options.filters.dateShort(item.date); }),"income-today":_vm.historicalRegistrationLoadingItems ? 0 : _vm.historicalRegistrationItemData[0].user_total,"income-yesterday":_vm.historicalRegistrationLoadingItems ? 0 : _vm.historicalRegistrationItemsData[6].user_total,"last-update":_vm.historicalRegistrationLoadingItems ? new Date() : _vm.historicalRegistrationLastUpdate,"is-loading":_vm.historicalRegistrationLoadingItems,"format-type":"Pengguna"}})],1)],1),_c('b-row',[(_vm.topProductItemsData.length)?_c('b-col',{attrs:{"xl":"4"}},[_c('ListCard',{attrs:{"title":"Product Leaderboard","data":_vm.topProductItemsData},scopedSlots:_vm._u([{key:"data",fn:function(ref){
        var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("number")(data.total_purchased))+" qty ")]}}],null,false,2215594320)})],1):_vm._e(),(_vm.topBrandItemsData.length)?_c('b-col',{attrs:{"xl":"4"}},[_c('ListCard',{attrs:{"title":"Brand Leaderboard","data":_vm.topBrandItemsData},scopedSlots:_vm._u([{key:"data",fn:function(ref){
        var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("number")(data.total))+" qty ")]}}],null,false,2793478720)})],1):_vm._e(),(_vm.topBuyerItemsData.length)?_c('b-col',{attrs:{"xl":"4"}},[_c('ListCard',{attrs:{"title":"Buyer Leaderboard","data":_vm.topBuyerItemsData},scopedSlots:_vm._u([{key:"data",fn:function(ref){
        var data = ref.data;
return [_vm._v(" Rp "+_vm._s(_vm._f("number")(data.total_expenses))+" ")]}}],null,false,2325658882)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }